*{
  margin: 0;
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
} 

html, * {
  max-height: 1000000px;
  -webkit-text-size-adjust:100%!important; 
  -moz-text-size-adjust:100%!important;
  -ms-text-size-adjust:100%!important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
html {
  width:100%;
  height: 100%;
  min-width: 320px;
  min-height: 100%;
  text-size-adjust:100%;
}

body{
  font-family: $font;
  color: $color-font;
  min-height: 100%; 
  min-width: 320px;
  background: $color-back;
}

h1 {font-size: 48px;}
h2 {font-size: 40px;}
h3 {font-size: 22px;}
h4 {font-size: 18px;}
h5 {font-size: 16px;}
h6 {font-size: 14px;}

h1 {
  margin: 40px 0;
}
h2 {
  margin: 0 0 35px;
}
h3 {
  margin: 0 0 30px 0;
  font-weight: 700;
  font-family: $font-second;
  line-height: 25px;
}
h4 {}
h5 {

}
h6 {

}
a{
  color: $color-font;
  text-decoration:none;
}

p {
  margin:0 0 em(25);
  
}
em {
  font-style:italic;
  }
b, strong {font-weight:900;}
small {font-size:0.857em}




img{max-width:100%;}
section {
  position:relative;
  z-index:0;
} 

.list{
  li{
    position: relative;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;
    /* &:not(:last-child){
      margin-bottom: 10px;
    } */
    &::before{
      content:'';
      width: 5px;
      height: 5px;
      background-color: $color-primary;
      border-radius:50%;
      position: absolute;
      left: 0;
      top:10px;
    }
  }
}

