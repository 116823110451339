// _variables.scss

$color-primary: 	#25b4d9;
$color-second:  	#00e79d;

 
$color-success: 	#68b572;
$color-error:   	darken(#ff0a0a, 15%);


$color-back: 		#FFF;
$color-back-second: #161515;
$color-burger: 		#434242;

$color-font:    	#333;
$color-font-other: 	#3b3d44;

$color-form-input:	#e3e5ec;
$color-form-input-second: #eff0f4;

$color-footer: 		#f6f6f6;

$xs:440px;
$_sm:767px;
$sm:768px; 
$md:992px; 
$lg:1170px; 
$xlg:1560px;

$grid_gutter: 15px;

$font:'PF BeauSans Pro', sans-serif;
$font-second:'Gotham Pro', sans-serif;


 