/* .form {
	&__item {
		position:relative;
		margin-bottom: rem(40);
		&>.checkbox{
			padding:rem(23) 0;
		}
	}
		&__label {
			color: $color-font-other;
			font-size: em(12);
			display: block;
			text-transform:uppercase;
			font-weight: 700;
			text-align: left;
			padding: 0 0 rem(20) rem(15);
		}

		/*&__placeholder {
			cursor: text;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			color: #666;
			&_hide {
				visibility: hidden;
			}
		}
} 


.form__field {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 48px;
	border-bottom: 2px solid rgba(51, 51, 51, .5); 
}

.form__pole { 
	width:100%;
	height:100%;
	display:inline-block;
	background:transparent;
	border:none
}



.form__pole,
.form__placeholder {
	padding: 0 rem(10);
	height: 48px;
	text-align:left;
	font-family: $font; 
	font-size: rem(24);
	font-weight: 300;
	line-height: 48px; 
	color: white;
}

*/



/*
.error {
	animation-duration:1s;
	animation-fill-mode:both;
	animation-name:field-error;
	border-color:$color-error; 
}

@keyframes field-error {
	10%,90%{transform:translate3d(-1px,0,0)}
	20%,80%{transform:translate3d(2px,0,0)}
	30%,50%,70%{transform:translate3d(-4px,0,0)}
	40%,60%{transform:translate3d(4px,0,0)} 
}
*/
/*
::-webkit-input-placeholder { 
  color: #666;
} 
:-moz-placeholder { 
  color: #666;    
}*/  
     
.form{
	width: 100%;
	position:relative;

	&__field{
		position:relative;
	    overflow: hidden;
	    margin-bottom: 5px;
	    height: 50px;
	    border-radius: 25px;
		background-color: white;
	}
	&__pole,
	&__text{
		font-family: $font;
	    font-size: 18px;
	    line-height: 50px;
	    padding: 0 15px;
	    text-align: center;
	}
	&_white{
		.form__field{
			background-color: white;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
		}
		.form__pole, .form__text{
			 color: #999;
		}
	}
} 



.form__col{
	position:relative;
	flex-basis: 265px;
    max-width: 265px;
}

.form_vertical .form__field{margin: 0 0 10px;width:100%!important;}
.form__pole{width:100%;height:100%;display:inline-block;background:transparent;border:none}
.form__text{cursor:text;position:absolute;width:100%;height:100%;top:0;left:0}

.form__text_hide{visibility:hidden}
.form__field_error{-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-name:field-error;animation-name:field-error}
.notice{position:fixed;color:#fff;text-align:center;background:rgba(0,0,0,.5);width:100%;left:0;bottom:0;text-align:center;padding:15px 0;line-height:1.4;z-index:9999}
@-webkit-keyframes field-error {
10%,90%{transform:translate3d(-1px,0,0)}
20%,80%{transform:translate3d(2px,0,0)}
30%,50%,70%{transform:translate3d(-4px,0,0)}
40%,60%{transform:translate3d(4px,0,0)}
}
@keyframes field-error {
10%,90%{transform:translate3d(-1px,0,0)}
20%,80%{transform:translate3d(2px,0,0)}
30%,50%,70%{transform:translate3d(-4px,0,0)}
40%,60%{transform:translate3d(4px,0,0)}
}

.leadbox{
	width: 100%;
	padding:30px 20px;
	text-align:center;
	background-color: #e3e6e8;
	&_second{
		padding:60px 50px;
		width: 654px;
		height: 449px;
		background: url('../images/forma.png') no-repeat;
		.form{
			width: 555px;
			margin-left:auto;
			margin-right:auto;
		}
	}
	&_primary{
		padding:50px 97px;
		width: 750px;
		height: 400px;
		background: url('../images/forms-large.png') no-repeat; 
		.form{
			width: 555px;
			margin-left:auto;
			margin-right:auto; 
		}
	}
}
.leadbox__header{
	font-size: 30px;
	line-height: 25px;
	margin-bottom: 5px;
}
.leadbox__title{
	font-size: 22px;
	line-height: 25px;
	margin-bottom: 15px;
}

.leadbox__header,
.leadbox__title{
	font-family: $font-second;
	font-weight: 700;
}

.form .btn{
    min-width: inherit;
    width: 100%;
    height: 65px;
    line-height: 65px;
    border-radius: 30px;
}
