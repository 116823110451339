/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {


}

.fancybox-outer, .fancybox-inner {
  position: relative;
  z-index: 0;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
}

.fancybox-close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    overflow: visible;
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    text-indent: -9999px;
    border: 0;
    outline: 0;
    background: #000;//transparent;
    z-index: 100;
    @include bp-md {
      width: 50px;
      height: 50px;
    }
  &::before,
  &::after {
    background: #ccc;
    content: '';
    position: absolute;
    top: 20px;
    right: 10px;
    width: 20px;
    height: 2px;
    transform: rotate(45deg);
    transition: background 0.3s ease;
    @include bp-md {
      top: 25px;
      right: 15px;
    }
  }
  &::after {
    transform: rotate(-45deg);
  }
  &:hover {
    &::before,
    &::after {
      background: $color-second;
    }
  }
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent; /* helps IE */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
  &::before,
  &::after {
    content: '';
    background: #ccc;
    width: 35%;
    height: 2px;
    position: absolute;
    top: 12px;
    left: 8px;
    transform: rotate(-45deg);
  }
  &::after {
    top: auto;
    bottom: 12px;
    transform: rotate(45deg);
  }
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
  &::before,
  &::after {
    left: auto;
    right: 8px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.fancybox-nav:hover span {
  opacity: 1;
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: visible !important;
    width: auto;
}

.fancybox-lock body {
    overflow: hidden !important;
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: rgba(0,0,0,.7);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
  /* fix */

  overflow-x: hidden;
}

/* Title helper */

.fancybox-title {
  visibility: hidden;
  font: normal em(14)/1.2 $font;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: em(10);
  left: 0;
  right: 0;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  padding: 10px 20px;
  background: transparent; /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  color: #FFF;
  line-height: 1.4;
  //white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, .8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
     only screen and (min--moz-device-pixel-ratio: 1.5),
     only screen and (min-device-pixel-ratio: 1.5){

  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url(fancybox_sprite@2x.png);
    background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
  }

  #fancybox-loading div {
    background-image: url(fancybox_loading@2x.gif);
    background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
  }
}