.container {
  padding: 0 $grid_gutter;
  margin: 0 auto;
  width: 1170px;
  position: relative;
}


.none {display:none;}
.is-locked {overflow: hidden;} 
.no-padding{padding: 0!important;}
.no-margin {margin: 0!important;}


.block-right{margin-left: auto;}
.block-left{margin-right: auto;}

.text-center{text-align: center !important}
.text-left{text-align: left !important}
.text-right{text-align: right !important}


.root-layout {
  min-height: 100%;
  max-width: 100%;
  overflow:hidden; 
}

.fancybox-margin.header{
    margin-right: -17px!important;
    padding-right: 17px!important;
}
// MAIN

.main {
  position: relative;
  min-height: 500px;
  max-height: 1000000px;
  width: 100%;
}



.image-wrap{
    font-size: 0;
    img{
        width: 100%; 
    }
}

.wfull{width: 100%}
.hfull{width: 100%}
          




.header{
    width:100%;
    height: 70px;
    position:fixed;
    top:0;
    z-index:110;
    background-color: rgba(245,245,245, .9);
    
    .container, .row{height: 100%}
}

.header-link{
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    color: #9b9b9b;
    &_underline{
        border-bottom:1px dashed;
    }
    &:hover{
        @extend .header-link_active;
    }
    &_active{
        position:relative;
        border-bottom:1px solid $color-second;
        color: black;
    }
}

.menu{
    position:relative;
    z-index:1;
    text-align:center;
    height: 100%;
    &__list{
        display:flex;
        height: 100%;
    }
    &__item{
        position:relative;
        &:not(:last-child){
            margin-right: 20px;
        }
        &_active a{
            @extend .header-link;
            @extend .header-link_active;
             color: black;
        }
       
    }
}


.section{
    position: relative;
    padding: 70px 0;
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &__title{
        padding:50px 0;
        margin-bottom: 30px;
        font-family: $font-second;
        font-size: 50px;
        font-weight: 900;
        span{
            display: inline-block;
            position: relative;
            &::before,
            &::after{
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 85px;
                height: 143px;
                margin:auto 0;
            }
            &::before{
                left: -90px;
                background: url(../images/title-before.png) no-repeat;
            }
            &::after{
                right: -90px;
                background: url(../images/title-after.png) no-repeat;
            }
        }
        sup{
            color: #ec6d5a;
        }
        &_white{
            color:#FFf;
        }
    }
    
    &_intro{
        margin-top: 70px;
        padding: 50px 0;
        background-image: url("../images/section_intro.jpg");
        color:#fff;
    }//intro

    &_about{
        background-color: whitesmoke;

        .btn{
            margin-top:60px;
        }
    }

    &_photos{
        background-image: url("../images/section_photos.jpg");
        min-height: 768px;
    }
    &_digital{
        background-color: whitesmoke;
    }

    &_rules{
        .intro-list__text{
            font-size: 18px;
            font-weight: 300;
            line-height: 18px;
            padding-left: 15px;
        }
        .intro-list__item{
            margin-bottom: 30px;
        }
        .leadbox{
            margin-top: 60px;
        }
    }

    &_franchise{
        background-image: url("../images/section_franchise.jpg");

    }
    
    &_founder{
        background-image: url("../images/section_founder.jpg");
    }

    &_startstep{
       background-image: url("../images/section_startstep.jpg");

       .intro-list__icon{
            width: 100px;
            height: 100px;
            flex-basis:110px;
            &_s1{background-image: url(../images/s1.png);}
            &_s2{background-image: url(../images/s2.png);}
            &_s3{background-image: url(../images/s3.png);}
            &_s4{background-image: url(../images/s4.png);}
            &_s5{background-image: url(../images/s5.png);}
            &_s6{background-image: url(../images/s6.png);}
            &_s7{background-image: url(../images/s7.png);}
            &_s8{background-image: url(../images/s8.png);}
            &_s9{background-image: url(../images/s9.png);}
       }
       .intro-list{ 
            &__text{
                text-align:left;
                font-size: 16px;
                font-weight: 300;
                line-height: 16px;
                strong{
                    display:block;
                    margin-top: 15px;
                    font-family: $font-second;
                    font-size: 17px;
                    font-weight: 900;
                    line-height: 16px; 
                }
            }
            &__item{ 
                margin-bottom: 30px;
                &_related{
                    position: relative;
                    &::before{
                        content:'';
                        width: 36px;
                        height: 137px;
                        background: url(../images/related.png) no-repeat;
                        position: absolute;
                        left: -40px;
                        top: 30px;
                    }
                    &:nth-child(even){
                        &::before{
                            top:48px;
                        }
                    }
                }
            }
       }
    }

    &_feedback{
        background-image: url("../images/section_feedback.jpg");
    }

} // section


.logo{
    width: 260px;
    height: 122px;
    background: url(../images/logo.png) no-repeat;
}

.contacts {
    display:flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    &__item{
        &:not(:last-child){
            margin-bottom: 5px;
        }
        *{
            display:inline;
            color:#fff;
        }
    }
    &__name{
        font-weight: 300;
        font-size: 15px;
    }
    &__link{
        font-family: $font-second;
        font-size: 21px;
        font-weight: 700;
    }
} //contacts


.intro-content{
    margin-top: 120px;
    h1{
        color: white;
        font-family: $font-second;
        font-size: 40px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        margin-bottom: 30px;
    }
    .intro-list{
        margin-bottom: 115px;
        &__item{
            width: 300px
        }
    }
} //intro-content

.intro-list{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    &__item{
        width: 100%;
        display:flex;
    }
    &__icon{
        width: 65px;
        height: 65px;
        flex-basis:80px;
        background-repeat: no-repeat;
        &_i1{background-image: url("../images/i1.png");}
        &_i2{background-image: url("../images/i2.png");}
        &_i3{background-image: url("../images/i3.png");}
        &_i4{background-image: url("../images/i4.png");}
        &_i5{background-image: url("../images/i5.png"); }
        &_i6{background-image: url("../images/i6.png");}
        &_i7{background-image: url("../images/i7.png");}
        &_i8{background-image: url("../images/i8.png");}


        &_i4,
        &_i5,
        &_i6,
        &_i7,
        &_i8{
            width: 90px;
            height: 90px;
            flex-basis:90px;
        }
    }
    &__text{
        align-self: center;
        font-size: 20px;
        font-weight: 300;
        line-height: 20px;
    }
}


.mini-tab{
    &__wrap{
        position: relative;
        overflow:hidden;
    }
    &__tab{
        display: none;
        &.is-active{
            display: block;
        }
        .video{
            font-size: 0;
            position: relative;
            cursor: pointer;

            &:not(.video_active){
                &::before,
                &::after{
                    content:'';
                    position: absolute;
                    left: 0;right: 0;
                    top: 0;bottom: 0;
                }
                &::before{
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(45deg, rgba(37, 180, 217, 0.64) 0%, rgba(125, 37, 185, 0.64) 100%);
                    box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.1);
                }
                &::after{
                    width: 93px;
                    height: 100px;
                    background: url(../images/play.png) no-repeat;
                    margin:auto;
                }
                &:hover{
                    &::after{
                        opacity:.7;
                    }
                }
            }
        }
    }
}
.mini-tab-nav{
    display:flex;
    justify-content: center;
    margin:15px 0;
    &__item{
        display:block;
        cursor:pointer;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        color: #fefefe;
        font-family: $font-second;
        font-size: 18px;
        font-weight: 700;
        background-image: url(../images/tab-link.png);
        &:not(:last-child){
            margin-right: 10px;
        }
        &:hover,
        &_active{
            background-position: 0 -36px;
            color: #333;
        }

        &:nth-child(2n){
            background-position: -36px 0;
            &:hover,
            &.mini-tab-nav__item_active{
                background-position: -36px -36px;
            }
        }
        &:nth-child(3n){
            background-position: -72px 0;
            &:hover,
            &.mini-tab-nav__item_active{
                background-position: -72px -36px;
            }
        }
    }
}



.gallery{
    padding:0;
    background-color: #c5c5c5;
    border: 5px solid white;
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.2);
    &__text{
        background-color:rgba(59,61,68, .8);
        height: 80px;
        position:absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: white;
        padding: 0 10%;
        display:flex;
        align-items:center;
        justify-content: center;
        &-inner{
            text-align: center;
        }
    }

    .swiper-button-white{
        top: inherit;
        bottom: 20px;
        height: 40px;
        width: 35px;
        background-size: contain;
    }

    .swiper-button-white{
        width: 35px;
        height: 35px;
        background: url(../images/arrow.png);
        &.swiper-button-prev{
            background-position: 0 0;
            &:hover{
                 background-position: 0 -35px;
            }
        }
        &.swiper-button-next{
            background-position: -35px 0;
            &:hover{
                 background-position: -35px -35px;
            }
        }
    }
}

.digital{
    position: relative;
    &::before{
        content:'';
        position: absolute;
        width: 677px;
        height: 488px;
        background-image: url('../images/digital.png');
        left: 0;right: 0;    top: -36px;
        margin:auto;
    }
    h3{
        font-family: $font-second;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 15px;
        white-space: nowrap;
    }
    h4{
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
    }
    &__item{
        margin-bottom: 90px;
    }
}

.uslugi{
    &__item{
        position: relative;
        margin-bottom: 30px;
        color:#fff;
        
        h3{
           text-align: center;
           padding:0 30px;
           width: 100%;

           font-family: $font-second;
            font-size: 26px;
            font-weight: 900;
            line-height: 25px;

        }   
        .image-wrap{
            width: 100%;
            box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.1);
            img{
                width: 100%;
            }
        }
    }
    &__middle{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        display:flex;
        align-items: center;
    }
}

.disclamer{
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;

    sup{
        color: #ec6d5a;
    }
}

.franchise{
    margin-bottom: 30px;
    position: relative;
    &::before{
        content:'';
        position: absolute;
        top: 0;bottom: 0;    left: -190px;right: 0;
        margin:auto;
        width: 577px;
        height: 539px;
        background: url(../images/franchise.png) no-repeat;
    }
    &__item{
        margin-bottom: 20px;
        h3{
            font-family: $font-second;
            font-size: 20px;
            font-weight: 700;
            line-height: 25px;
        }
        .list{
            li{
                font-size: 16px;
                font-weight: 300;
                line-height: 14px;
                padding-left:10px;
                &:not(:last-child){
                    margin-bottom: 15px;
                }
                &::before{
                    top:5px;
                }
            }
        }
    }
}

.avantages{
    &__icon{
        width: 165px;
        height: 165px;
        margin:0 auto 15px;
        &_i1{background-image: url("../images/p1.png")}
        &_i2{background-image: url("../images/p2.png")}
        &_i3{background-image: url("../images/p3.png")}
        &_i4{background-image: url("../images/p4.png")}
        &_i5{background-image: url("../images/p5.png")}
        &_i6{background-image: url("../images/p6.png")}
        &_i7{background-image: url("../images/p7.png")}
        &_i8{background-image: url("../images/p8.png")}
    }
    &__item{
        text-align: center;
        margin-bottom: 30px;
        h3{
            font-family: $font-second;
            font-size: 20px;
            font-weight: 700;
            line-height: 25px;
            margin-bottom: 10px;
        }
        h4{
            font-size: 16px;
            font-weight: 300;
            line-height: 16px;
            margin-bottom: 0;
        }
    }
}

.founder{
    .diver{
        width: 110px;
        height: 17px;
        background-image: url("../images/diver.png");
        margin:15px auto;
    }
    .btn{
        min-width: inherit;
        width: 100%;
    }
    &__wrap{
        padding: 0 60px 30px;
        height: 660px;
        text-align: center;
        background-size: cover !important;
        position: relative;
        margin-bottom: 30px;
        &::before{
            content: '';
            position: absolute;
            z-index: -1;
            width: 518px;
            height: 721px;
            background: url(../images/founder-primary.png) top no-repeat;
            top: -30px;
            left: -30px;
        }
        &_second{
            &::before{
                background: url(../images/founder-second.png) top no-repeat;
            }
        }
        .image-wrap{
            width: 200px;
            height: 200px;
            background-color: rgba(255, 255, 255, 0.05);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
            border-radius:50%;
            padding:5px;
            overflow:hidden;
            margin:0 auto 20px;
            img{
                width: 100%;
                border-radius:50%;
            }
        }
        h3{
            font-family: 'Markella';
            font-size: 35px;
            margin-bottom: 20px;
            font-weight: normal;
        } 
        h4{
            font-size: 20px;
            font-weight: 400;
            line-height: 20px;
            //margin-bottom: 15px;
        }
        ul{
            margin:10px 0;
            li{
                font-size: 18px;
                line-height: 20px;
            }
            &.small{
                li{
                    font-size: 16px;
                    line-height: 14px;
                    margin-bottom: 10px
                } 
            }
        }
    }
}


.footer{
    padding:50px 0;
    color:#000;
    a{
        color:#000;
    }
    .contacts__item *{
         color:#000;
    }
    .logo{
        width: 164px;
        height: 77px;
        background-image: url('../images/logo_black.png');
    }
    &__politica{
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
        text-decoration: underline;
    }
}