.btn-wrap {
  padding: rem(30) 0;
  @include bp-sm {
    padding: rem(50) 0;
  }
  &_center {
    text-align: center;
  }
}

.btn {
  background: none;
  display: inline-block;
  vertical-align: top;
  min-width: 200px;
  height: 50px;
  padding: 0 20px;
  border: 0;
  outline: 0;
  transition: all .3s ease;
  text-align: center;
  cursor: pointer;
  
  color: $color-font;
  font-family: $font;
  text-decoration: none;
  appearance: none;
  white-space: nowrap;

  font-size: 18px;
  border-radius: 25px;


  &_primary,
  &_primary:hover,
  &_primary:active {
    background-color: $color-second;

  }
  
  &_primary:hover {
   background-color: #24f3b1;
  }
  &_primary:active {
    transform: translateY(1px);
  }

  
  
  &_lg{
    border-radius: 30px;
    min-width: 555px;
    height: 65px;
    font-size: 20px;
    font-weight: 400;
  }

  &_success,
  &_success:hover,
  &_success:active {
    color: white;
    background: $color-success;
    cursor:default;
  }
  &_outline,
  &_outline:hover,
  &_outline:active {
    background: none;
    box-shadow: inset 0 0 0 2px #999;
    color: #999;
  }
    &_outline:hover {
      background: $color-second;//#999;
      box-shadow: none;
      color: $color-font;
    }
    &_outline:active {
      background: darken($color-second, 5%);
      box-shadow: none;
      color: $color-font;
    }


  &_sm {
    font-size: rem(12);

    line-height: rem(36);
    height: rem(34);
    min-width: 0;
    padding: 0 rem(25);

  }
  &_lg {

  }
  &:disabled{
    cursor: no-drop;
    background: #efefef;
    color:$color-font;
  }
}



.link {
  font-weight: 900;
  color: $color-font-other;
  border-bottom: 2px solid $color-font-other;
  text-decoration: none;
  &:hover {
    border-color: transparent;
  }
  &_lg {
    font-size: rem(18);
  }
}

.btn-title{
  font-size: 15px;
  margin-top: 5px;
}