.arcticmodal-overlay,
.arcticmodal-container{ position: fixed; left: 0; top: 0; right: 0; bottom: 0; z-index: 1000; }
.arcticmodal-container{ overflow: auto; margin: 0; padding: 0; border: 0; border-collapse: collapse; }
*:first-child+html .arcticmodal-container { height: 100% }
.arcticmodal-container_i{ height: 100%; margin: 0 auto; }
.arcticmodal-container_i2 { padding: 24px; margin: 0; border: 0; vertical-align: middle; }
.arcticmodal-error { padding: 20px; border-radius: 10px; background: #000; color: #fff; }
.arcticmodal-loading {}

.arcticmodal-overlay{
  background: rgba(255, 255, 255, .9);
}


.arcticmodal-close {
      position: absolute;
      top: rem(15);
      right: rem(15); 

      display: block;
      overflow: visible;

      //width: 50px;
      //height: 50px;
      width: 11px;
      height: 11px;
      margin: 0;
      padding: 0;

      cursor: pointer;
      text-decoration: none;
      text-indent: -9999px;

      border: 0;
      outline: 0;
      background: transparent;

      z-index: 100;
      &::before,
      &::after {
        //background: #ccc;
        background-color: #c23d3d;
        content: '';
        position: absolute;
        top: 9px;
        right: 3px;
        width: 15px;
        height: 2px;
        transform: rotate(45deg);
        transition: background .3s ease;
      }
      &::after {
        transform: rotate(-45deg);
      }
      &:hover {
        &::before,
        &::after {
          background: $color-error; 
        }
      }
    }
.modal{
        position: relative;
    padding: 30px 20px;
    width: 750px;
    background-color: #ffe371;
    border: 5px solid white;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
    .form{
      width: 555px;
      margin:auto;
    }
}
.modal .leadbox__header{
  padding: 0;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 30px;
}
.modal .leadbox__title{
  font-size: 22px;
  margin:10px 25px 15px; 
}


.succesBox{position:relative}
.arcticmodal-close{position:absolute;cursor:pointer;z-index:1;
  width: 20px;
  height: 20px;
  color: #e24b23;
  font-family:"Georgia";text-align:center;font-size:26px;line-height:30px;text-shadow: 0 1px 0 #8f2e14;}
.orderbox{margin:0 auto;padding:15px 0;text-align:center;position:relative;}
.orderbox .form{margin:auto; display:block!important}
.orderbox .form__field{width:100%;}
.orderbox .form .btn{
  width:100%; 
  max-width: inherit;
  height: 58px;
  font-size: 20px;
  line-height: 58px;
}
.modal .arcticmodal-close{right: -10px;top: -20px;}
.succesBox .arcticmodal-close{top: 10px;
    right: 10px;}

.modal .orderbox{padding:0;}


.modalWrap{margin:0 auto;padding: 10px 20px;text-align:center;position:relative;}
.modalWrap__aimg{display:block;text-align:center;background:url(../images/present.png) no-repeat;height:276px;width:370px;margin:0 auto;position:absolute;top:72px;left:72px}
.modalWrap__a{color:#0cf;font-size:22px;font-weight:500;border-bottom:1px solid;display:inline-block;margin-top:240px}
.modalWrap__p{color:#303030;font-size:16px;font-weight:500;line-height:30px;margin-bottom:20px;white-space:nowrap}
#succesContent{display:none}
.infomodal{width:600px;padding:20px;background-color:#fff;box-shadow:0 0 15px rgba(0,0,0,0.25);position:relative;border-radius: 5px;}
.infomodal h2{font-size:22px;font-weight:400;margin-bottom:20px;font-family:Arial;}
.infomodal p{font-size:16px;line-height:22px;font-family:Arial;text-align: justify;}
.orderbox__success-header{padding-top:25px;padding-bottom:10px;color:#fff;font-size:30px;font-weight:700;line-height:18px}
.orderbox__success-title{color:#fff;font-family:Arial;font-size:18px;font-weight:400;line-height:18px;padding-top:10px}
.orderbox__presentation{width:250px;height:168px;display:block;margin:auto;position:relative}
.orderbox__presentation::before{content:"";width:302px;height:236px;display:block;position:absolute;top:-23px;left:-26px}
.orderbox__p-title{color:#fff;font-size:18px;font-weight:400;line-height:18px}
.orderbox__p-btn{width:230px!important;height:38px;padding:0 10px;line-height:38px;border-radius:8px;display:block;margin:15px auto!important;position:relative;z-index:1;-webkit-transition:all .3s ease-in-out;-moz-transition:all .3s ease-in-out;-o-transition:all .3s ease-in-out;transition:all .3s ease-in-out}


.succesBox .leadbox__title,
#succesContent .leadbox__title{padding-bottom:0;    margin-bottom: 0;}