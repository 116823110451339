
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

$font-size: 16; // Default

@function em($pixels, $context: $font-size) {
  @return #{$pixels/$context}em;
}
@function rem($pixels, $context: $font-size) {
  @return #{$pixels/$context}rem;
}



/* MEDIA
----------------------------------------------------------------------*/

@mixin bp-xlg {
  @media only screen and (min-width: $xlg) {
    @content;
  }
}

@mixin bp-lg {
  @media only screen and (min-width: $lg) {
    @content;
  }
}
 
@mixin bp-md {
  @media only screen and (min-width: $md) {
    @content;
  }
}

@mixin bp-sm {
  @media only screen and (min-width: $sm) {
    @content;
  }
}
@mixin bp-xs { 
  @media only screen and (max-width: $_sm) {
    @content;
  }
}


//
@mixin bp-max-xlg {
  @media only screen and (max-width: $xlg) {
    @content;
  }
}

@mixin bp-max-lg {
  @media only screen and (max-width: $lg) {
    @content;
  }
}

@mixin bp-max-md {
  @media only screen and (max-width: $md) {
    @content;
  }
}

@mixin bp-max-sm {
  @media only screen and (max-width: $sm) {
    @content;
  }
}
@mixin bp-max-xs { 
  @media only screen and (max-width: $_sm) {
    @content;
  }
}
//

// height
@mixin bp-h-sm {
  @media only screen and (min-height: 500px) {
    @content;
  }
}
@mixin bp-h-md {
  @media only screen and (min-height: 800px) {
    @content;
  }
}
@mixin bp-h-lg {
  @media only screen and (min-height: 900px) {
    @content;
  }
}



//@include respond-to(tablets) { width: 240px; };