@font-face {
  font-family: 'ALS Rubl';
  src: url('../fonts/ALSRubl.eot');
  src: url('../fonts/ALSRubl.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ALSRubl.woff') format('woff'),
    url('../fonts/ALSRubl.ttf') format('truetype'),
    url('../fonts/ALSRubl.svg#ALSRubl') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro-Light.eot');
  src: url('../fonts/PFBeauSansPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFBeauSansPro-Light.woff') format('woff'),
    url('../fonts/PFBeauSansPro-Light.ttf') format('truetype'),
    url('../fonts/PFBeauSansPro-Light.svg#PFBeauSansPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro-Regular.eot');
  src: url('../fonts/PFBeauSansPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFBeauSansPro-Regular.woff') format('woff'),
    url('../fonts/PFBeauSansPro-Regular.ttf') format('truetype'),
    url('../fonts/PFBeauSansPro-Regular.svg#PFBeauSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Bold.eot');
  src: url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GothamPro-Bold.woff') format('woff'),
    url('../fonts/GothamPro-Bold.ttf') format('truetype'),
    url('../fonts/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
  font-weight: 700; 
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Black.eot');
  src: url('../fonts/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/GothamPro-Black.woff') format('woff'),
    url('../fonts/GothamPro-Black.ttf') format('truetype'),
    url('../fonts/GothamPro-Black.svg#GothamPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'Markella';
  src: url('../fonts/Markella.eot');
  src: url('../fonts/Markella.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Markella.woff') format('woff'),
    url('../fonts/Markella.ttf') format('truetype'),
    url('../fonts/Markella.svg#Markella') format('svg');
  font-weight: normal;
  font-style: normal;
}
