  .input {
  	background: none;
    color: $color-font;
    font: 700 1rem $font;
    width: 100%;
    height: 50px;
    line-height: 50px;
    min-width: 200px;
    border-radius: 25px;
    border: 0;
    border-radius: 0;
    padding: 0 rem(20);
    box-shadow:none;
    outline:none;
    border: 2px solid $color-form-input;
    transition: all .2s;

    &.error,
    &.required,
    &:focus:invalid {
      border-color: $color-primary;
    }
    &.valid,
    &:focus:valid {
      border-color: $color-form-input-second;
      background: $color-form-input-second;
    }

    &--outline {
      border-color: #f2f2f2;
    }
  }

  .checkbox{

    input[type=checkbox]{
      display:none;
      &:checked + label::after{
        content:"";
        width: rem(10);
        height: rem(10);
        left: rem(4);
        background-color: $color-primary;
      }
    }
    label{
      cursor:pointer;
      padding-left: 30px;
      width: 100%;
      display: inline-block;
      position: relative;
      color: #b9b9b9;
      
      &::before{
        content:"";
        width: rem(18);
        height: rem(18);
        background-color: white;
        border: 2px solid $color-form-input;
        
      }
      &::after, &::before{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin:auto 0;
      }
    }
  }


